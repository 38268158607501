import { Flex, Group } from '@mantine/core'
import { useRouter } from 'next/router'
import { useCurrentAuthState } from '~/context'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { Typography } from '~/components/shared/text/Typography'
import { BANNER_HEIGHT } from '~/utils/constants'

export const MindtripRedirectHeader = () => {
    const router = useRouter()
    const { email } = useCurrentAuthState()
    return (
        <Flex
            h={BANNER_HEIGHT}
            bg="appFuscia.0"
            direction="column"
            align="center"
            justify="center"
            // sx={{ cursor: 'pointer' }}
            // onClick={() => router.push('/blog/company-news/the-next-era-for-thatch')}
        >
            <Group
                spacing={4}
                align="center"
            >
                <Typography
                    variant="caption"
                    align="center"
                    color="appBlack.0"
                >
                    ✨ Thatch is moving to <a href="https://www.mindtrip.ai" target='__blank' className='underline'>Mindtrip</a>!{' '}
                    <ThatchLink
                        to="https://www.thatch.co/blog/company-news/the-next-era-for-thatch"
                        sx={{ textDecoration: 'underline' }}
                    >
                        Learn More
                    </ThatchLink>
                </Typography>
            </Group>
        </Flex>
    )
}
